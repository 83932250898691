body {
    margin: 0;
    padding: 0;
    overflow: hidden auto;
    overflow: overlay;
    user-select: none;
    /* font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; */
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #eee;
    overflow-x: hidden;
    /* width: 100vw; */
}

.navigation a {
    text-decoration: none;
}

.btn-sm {
    font-size: 13px;
    min-height: 2rem;
}

.bg-primary {
    background-color: #2196f3;
}

.badge {
    font-size: 12px;
    font-weight: 400;
    border-radius: 0.2rem;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: rgba(255,255,255,0.2); */
    z-index: 999;
}

.gray-icon {
    height: 19px;
    filter: grayscale(50%);
    display: initial;
}

@keyframes anim-row {
    0% {
        min-height: 0px;
        max-height: 0px;
        opacity: 0;
        transform: translateY(-5px);
    }

    100% {
        min-height: 3rem;
        max-height: 4rem;
        opacity: 1;
        transform: translateY(0px);
    }
}

.row-hover.deleted {
    animation: anim-row 150ms normal ease-out;
}

.row-hover {
    /* height: 4rem; */
    overflow: hidden;
    min-height: 3rem;
    max-height: 4rem;
    position: relative;
    cursor: pointer;
}

.row.border-bottom {
    /* background: #0033cc99; */
    /* color: white; */
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
    padding: 7px 0px;
}

.row-hover:nth-child(odd):after {
    content: ' ';
    background-color: #0033cc0A;
    position: absolute;
    width: 100%;
    height: 100%;
}

.row-hover:hover:after {
    content: ' ';
    background-color: #00000010;
    position: absolute;
    width: 100%;
    height: 100%;
}

.row-hover .image {
    min-height: 4rem;
}

.image {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;

    min-width: 12px;
    min-height: 12px;
    overflow: hidden;
}

.image i {
    padding: 10px;
}

a {
    color: #444;
}

a:hover {
    color: #03c;
    text-decoration: underline;
}

.col.sort i {
    transition: all 0.15s ease-in-out;
    white-space: nowrap;
}

.modal-header {
    padding: 12px 15px 8px 15px;
    align-items: center;
    /* border-bottom:  */
    /* background: url(assets/images/header2.jpg), linear-gradient(to right, #03c 0px, #03c 100%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat, no-repeat;
    background-attachment: initial, initial;
    background-origin: initial, initial;
    background-clip: initial, initial;
    background-color: initial;
    color: #fff; */
}

input {
    font-family: 'Open Sans'!important;
    font-size: 12px!important;
    color: #505050!important;
}
input.input {
    font-family: 'Open Sans';
    font-size: 12px;
    height: 2rem;
    
    outline: 0!important;
    border: 1px solid #cccccccc;
    border-radius: 3px;
    width: 100%;
    padding: 0.35rem 0.4rem !important;
    transition: border 0.05s;
}
input.input:hover, input.input:active, input.input:focus {
    border: 1px solid #0033cc88;
    box-shadow: 0 0px 0.5px 0.5px rgba(0,0,0,0.1);
}
/* 
.modal-content {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 12px -4px, rgba(0, 0, 0, 0.16) 0px 2px 4px -2px;
    border: none;
    color: rgb(74, 74, 74);
    border-radius: 3px;
} */

.modal {
    /* pointer-events: none; */
    overflow: auto;
}

@keyframes anim-modal {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.modal {
    animation: anim-modal 150ms normal ease-out;
}

@keyframes anim-modal-backdrop {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

.modal-backdrop {
    opacity: 0.5;
    background-color: #333;
    animation: anim-modal-backdrop 150ms normal ease-out;
}

.modal-title {
    font-size: 16px;
    font-weight: 400;
}

.row-hover .badge {
    vertical-align: text-top;
}

.no-image {
    background-image: url('assets/images/noimage.png');
}


@keyframes anim-tooltip {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
.tooltip {
    display: inline;
    opacity: 1;
    bottom: 100%;
    font-size: 12px;
    animation: anim-tooltip 0.08s normal ease-out;
}
.tooltip .tooltip-inner {
    background: #444;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 8px;
}
.tooltip .arrow {
    text-align: center;
}
.tooltip .arrow:before {
    border-top-color: #444;
    text-align: center;
}
.tooltip-overlay {
    position: absolute;
    display: inline;
    min-width: 10px;
    min-height: 10px;
    background: red;
}
.pagination-buttons button {
    min-width: 36px;
}
.content-wrapper {
    max-width: 100vw;
    flex: 1;
}

.footer * {
    color: #777!important;
}

.btn {
    outline: 0;
    box-shadow: none;
    transition: background 0.05s ease-in-out!important;
    /* background: initial!important; */
}

.btn.hover, .btn:hover {
    box-shadow: inset 0 0 0.1rem 0.05rem #00000022!important;
    border-color: #00000022!important;
}
.btn.focus, .btn:focus,
.btn.active, .btn:active  {
    box-shadow: none!important;
}

::-webkit-scrollbar {
    width: 0.5rem;
}
body::-webkit-scrollbar-track {
    background: none;
}
.dropdown-menu::-webkit-scrollbar {
    background: #ffffffee;
}
.navbar-nav .dropdown-menu {
    max-height: 0px;
}
.navbar-nav .dropdown.show .dropdown-menu {
    max-height: 500px;
}
::-webkit-scrollbar-thumb {
    background: #555555aa;
    border-radius: 0.5rem;
}
::-webkit-scrollbar-thumb:hover {
    background: #555555;
}

.page-header {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    padding: 1.25rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 12px -4px, rgba(0, 0, 0, 0.16) 0px 2px 4px -2px;
    background: #fff;
}

small {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}

.footer {
    /* position: absolute; */
    flex: 1 0;
    /* bottom: 0px; */
    width: 100%;
    max-height: 50px;
    min-height: 50px;
}

.bg-dark {
    background: 
        url(assets/images/header2.jpg), 
        linear-gradient(to right, #03c 0px, #03c 100%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat, no-repeat;
    background-attachment: initial, initial;
    background-origin: initial, initial;
    background-clip: initial, initial;
    background-color: initial;
    color: #fff;
}

.bg-dark a {
    color: #fff;
}

.dropdown-toggle::after {
    font-family: icomoon;
    display: inline-block;
    border: 0;
    vertical-align: middle;
    font-size: 0.6875rem;
    margin-left: 0.46875rem;
    line-height: 1;
    position: relative;
    content: '';
}

.badge-mark {
    display: inline-block!important;
    font-size: 12px;
    padding: 0;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100px;
    background-color: #ffa726;
}

.flex-1 {
    flex: 1;
}

.flex-auto {
    flex: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
}

.card-header {
    background: none!important;
    padding: 17px;
}

h6 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.card-body li.nav-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.card {
    background: #fafafa;
}

.Toastify__toast {
    background: #fff!important;
    color: #444!important;
    font-family: inherit!important;
    box-shadow: #aaaaaa20 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 6px 20px -4px, rgba(0, 0, 0, 0.08) 0px 4px 12px -2px!important;
}

.Toastify__toast:after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 5px;
    opacity: 0.5;
    top: 0;
    left: 0;
}

.Toastify__progress-bar {
    top: 0!important;
}

.Toastify__toast--info .Toastify__progress-bar {
    background: #007ac2!important;
}
.Toastify__toast--info.Toastify__toast:after {
    background: #007ac2!important;
}
.Toastify__toast--info i {
    color: #007ac2!important;
}

.Toastify__toast--success .Toastify__progress-bar {
    background: #35ac46!important;
}
.Toastify__toast--error.Toastify__toast:after {
    background: #35ac46!important;
}
.Toastify__toast--success i {
    color: #35ac46!important;
}

.Toastify__toast--error .Toastify__progress-bar {
    background: #e65240!important;
}
.Toastify__toast--error.Toastify__toast:after {
    background: #e65240!important;
}
.Toastify__toast--error i {
    color: #e65240!important;
}

.Toastify__close-button {
    color: #aaa!important;
}

.Toastify__toast-body {
    display: flex!important;
    align-items: center;
    align-content: center;
}

.Toastify__toast-body div {
    padding: 0.2rem;
}

.Toastify__toast-body i {
    margin-right: 0.5rem;
}

.badge-primary {
    background: rgb(40, 144, 206)!important;
}




@keyframes blink {
    0% {
       /* background: ; */
    }
    50% {
        box-shadow: 0 0 0px 3px #00000055;
    }
    100% {
    }
}

.price {
    font-size: 16px;
    margin-right: 20px;
    text-align: end;
}
@media (max-width: 768px) {
    .price {
        width: 100%;
        order: -1;
    }
}