.navbar-light {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: #fafafa!important;
}

.navbar-light .navbar-nav i {
    color: #666;
}

.navbar-light .nav-link {
    position: relative;
    padding: 0.65rem 0.8rem!important;
    color: #6a6a6a!important;
    display: flex;
    align-items: center;
    /* transition: box-shadow 150ms ease-in-out 0s; */
    /* transition: all 150s; */
}

.navbar-light .nav-link:hover {
    color: #000!important;
    background: #fafafa;
}

.navbar-light .nav-link:after {
    content: ' ';
    position: absolute;
    background: none;
    width: 100%;
    height: 3px;
    left: 0px;
    bottom: -1px;
    transition: background 150ms ease-in-out 0s;
}

.navbar-light .nav-link:hover:after {
    background: #dfdfdf;
}

.navbar-light .nav-link:hover i,
.navbar-light .nav-link.active i {
    color: #333!important;
}

.navbar-light .nav-link.active {
    color: #111!important;
    /* box-shadow: 0 3px 0 0 #007ac2; */
}

.navbar-light .nav-link.active:after {
    background: #007ac2;
}