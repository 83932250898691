.calendar {
    user-select: none!important;
    background: #fff;
    /* box-shadow: 0 -1px 0 #ddd, -1px 0 0 #ddd; */
    border: 1px solid #ddd;
    width: 100%;
}

.cell-head {
    font-size: 14px;
    font-weight: 500 !important;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    height: 34px;
    overflow: hidden;
    box-shadow: 0 1px 0 #ddd, 1px 0 0 #ddd;
    user-select: none;
    /* z-index: 2; */
}

.col.cell-head {
    padding: 8px;
    font-size: 12px;
}

.cell {
    padding: 12px;
    height: 44px;
    box-shadow: 0 1px 0 #ddd, 1px 0 0 #ddd;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background 0.05s;
    border-top: 1px solid transparent;
}

.cell:hover {
    background: #f5f5f5;
    border-top: 1px solid #ddd;
}

.timeblock {
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    border-radius: 2px;
    outline: 1px solid #ffffff55;
    background: #007ac2;
    height: 40px;
    color: #fff;
    padding: 2px;
    /* box-shadow: 0 1px 0px #ddd; */
    overflow: hidden;
    user-select: none;
}

.checker {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#eee 1px, transparent 1px), linear-gradient(to right, #ddd 1px, transparent 1px);
    /* background-size: 10% 44px; */
}

.checker-line {
    height: 100%;
    background: red;
    width: 2px;
    /* z-index: 99; */
    left: 115px;
    position: absolute;
    box-shadow: 0 0 1px 1px #ffffff77;
}

.context {
    z-index: 10000;
    position: absolute;
    width: 200px;
    height: auto;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.2));
}

.noreservations {
    background: rgb(255, 255, 255);
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-bottom: 0;
    border-right: 0;
}