.calcite-button {
    align-items: center;
    appearance: none;
    background-color: #007ac2;
    border: 1px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: "Avenir Next W00", Avenir, "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-stretch: 100%;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-weight: 400;
    height: 32px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 15.96px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    outline-color: rgba(0, 0, 0, 0);
    outline-offset: 0px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 7px;
    position: relative;
    text-align: center;
    text-decoration-color: rgb(255, 255, 255);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-indent: 0px;
    text-rendering: auto;
    text-shadow: none;
    text-transform: none;
    transition-delay: 0s, 0s, 0s, 0s, 0s, 0s;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.1s, 0.1s, 0.15s;
    transition-property: color, background-color, border, box-shadow, outline-offset, outline-color, -webkit-box-shadow;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    user-select: none;
    visibility: visible;
    width: auto;
    min-width: 100px;
    word-spacing: 0px;
    writing-mode: horizontal-tb;
    -webkit-font-smoothing: antialiased;
    -webkit-border-image: none;
    border-image: none;
}

.calcite-button:hover {
    background: rgb(40, 144, 206);
}

.calcite-button:focus {
    outline: 2px solid #007ac2 !important;
    outline-offset: 1px;
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
}

.calcite-button>i {
    user-select: none;
}

.calcite-button-light {
    background-color: #eaeaea;
    border: 1px solid #ccc;
    color: #151515;
}

.calcite-button-light:hover {
    background-color: #f3f3f3;
    color: #151515;
}

.calcite-button-light>i {
    color: #151515;
}

.calcite-button-clear {
    color: rgb(0, 97, 155);
    border: 1px solid rgb(0, 97, 155);
    background: none;
}

.calcite-button-clear>i {
    color: rgb(0, 97, 155);
}

.calcite-button-clear:hover {
    color: rgb(0, 97, 155);
    background: none;
    box-shadow: rgb(0, 97, 155) 0px 0px 0px 1px inset;
}


.calcite-button-white {
    background: #fff;
    color: #151515;
    outline: 0px solid #007ac2!important;
}

.calcite-button-white>i {
    color: #151515;
}

.calcite-button-white:hover {
    /* box-shadow: rgb(0, 97, 155) 0px 0px 0px 1px inset; */
    background: #efefef;
    color: #000;
}


.btn-group.calcite-button-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    border: 1px solid #cacaca;
    background: #fff;
}

.btn-group.calcite-button-group .calcite-button {
    height: 26px;
    margin: 2px;
    outline: 3px solid #007ac2;
}

.pagination-buttons button {
    border: 0;
    outline: 0;
    background: none!important;
    color: #151515!important;
    font-weight: 400;
    border-bottom: 3px solid transparent;
    padding: 0px 0px 5px 0px;
}

.pagination-buttons button:hover {
    border: 0;
    outline: 0;
    background: none!important;
    color: #151515!important;
    font-weight: 500;
    border-bottom: 3px solid #dfdfdf;
}

.pagination-buttons button:focus,
.pagination-buttons button:active,
.pagination-buttons button.active {
    border: 0;
    outline: 0;
    background: none!important;
    color: #151515!important;
    font-weight: 500;
    border-bottom: 3px solid #007ac2;
}