.page {
    padding: 1.25rem 1.25rem;
    flex: 1;
    flex-grow: 1;
    background-color: #eee;
}

.content {
    margin: 1.25rem;
}

.page-content {
    flex: 1;
    background-color: #eee;
    background-image: url('assets/images/bg.png');
    background-size: cover;
    background-position: center;
}

.ellipsis {
    max-height: 100%;
    max-height: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: initial;
    white-space: nowrap;
    padding: 0.3rem 0.8rem;

    display: block;
    align-items: baseline;
}

.col.sort {
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    /* padding: 0px 0.2rem; */
    padding: 0rem 0.8rem;

    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: initial;
    white-space: nowrap;
}

.card {
    margin-bottom: 1.25rem;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-width: 0;
}