[class*=' icon-'],
[class^='icon-'] {
    speak: none;
    font-style: normal;
    /*font-weight: 400;*/
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /*min-width: 1em;*/
    display: inline-block;
    text-align: center;
    /*font-size: 1rem;*/
    vertical-align: middle;
    position: relative;
    top: -1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-1x {
    font-size: 1rem;
}
.icon-2x {
    font-size: 2rem;
}
.icon-3x {
    font-size: 3rem;
}
.icon-4x {
    font-size: 4rem;
}
.icon-5x {
    font-size: 5rem;
}
.icon-rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.icon-rotate-180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.icon-rotate-270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1);
}
.icon-flip-horizontal.icon-flip-vertical {
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
}

@keyframes anim-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
.spinner {
    animation: anim-rotate 2s infinite linear;
    text-shadow: 0 0 .25em rgba(255,255,255, .3);
}