.dropdown input {
    position: absolute !important;
    top: 0px;
    left: 0px;
    cursor: pointer;
    /* border: 0 !important;
    outline: 0 !important;
    font-size: 13px;
    border-radius: .2rem;
    height: 100%;
    width: 100%; */
}

/* .dropdown input:hover {
    background-color: #eee!important;
} */

.dropdown div {
    transition: all 150ms ease-in-out 0s;
}

.dropdown-menu:hover {
    /* background-color: #eee!important; */
    border-color: #ccc !important;
}

.dropdown .dropdown-arrow {
    pointer-events: none;
    flex: 1;
    text-align: right;
}

.dropdown .dropdown-arrow i {
    transition: transform 150ms ease-in-out 0s;
}

.dropdown-item {
    color: #333 !important;
    font-size: 0.75rem;
    padding: 7px 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: #fff;
    transition: padding 150ms ease-in-out 0s;
    cursor: pointer;
    min-height: 32px;
}

.dropdown-item .dropdown-icon {
    color: #bbb;
    width: 0px!important;
    margin: 0px!important;
    overflow: hidden;
    transition: width 150ms ease-in-out 0s, margin 150ms ease-in-out 0s;
}

.dropdown-item:hover .dropdown-icon {
    color: #bbb;
    width: 1rem!important;
    margin-right: .25rem!important;
}

.dropdown-item.active .dropdown-icon {
    color: #007ac2;
    width: 1rem!important;
    margin-right: .5rem!important;
}


/* .dropdown-item:before {
    font-family: 'icomoon-ultimate';
    content: "\edc8";
    position: absolute;
    opacity: 0;
    transition: opacity 150ms ease-in-out 0s;
    font-size: 14px;
    left: 0.5rem;
}

.dropdown-item:hover:before {
    color: #aaa;
    transition: opacity 150ms ease-in-out 0s;
    opacity: 1;
}

.dropdown-item.active:before {
    font-family: 'icomoon-ultimate';
    color: #007ac2!important;
    content: "\ed6f";
    opacity: 1;
    font-size: 10px!important;
} */

.dropdown-item:hover {
    background-color: #eee;
    /* padding-left: 1.35rem; */
    /* font-weight: 500; */
}

.dropdown-menu b {
    color: inherit;
}

.dropdown-item.active {
    color: #333 !important;
    background: #fff !important;
    text-decoration: none;
    font-weight: 500;
    /* padding-left: 1.35rem; */
}

.dropdown-item.active:hover,
.dropdown-item:active:hover {
    background: rgb(243, 243, 243) !important;
}

.dropdown-toggle:hover {
    color: #ccc;
}

.dropdown-menu {
    border: 1px solid #fff;
    border-radius: 0;
    /* box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1); */
    /* max-height: 0px; */
    overflow: hidden auto;
    overflow: overlay;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 16px 0px;
    padding: 0;
    margin: 0;
    opacity: 0 !important;
    transition: opacity 150ms ease-in-out 0s, transform 150ms ease-in-out 0s;
    visibility: hidden;
    /* min-width: 8rem; */
    max-height: 350px;
    display: block!important;
    width: 100%;
    transform: translateY(-5px);
    pointer-events: none;
}

.dropdown.show .dropdown-menu,
.dropdown-menu.show {
    visibility: visible;
    z-index: 9999 !important;
    opacity: 1 !important;
    transform: translateY(0px);
    pointer-events: initial;
}

.dropdown-content-header {
    position: sticky;
    top: -0.5rem;
    margin-top: -0.5rem;
    background: #fff;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    z-index: 9;
    display: flex;
    justify-content: space-between;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem;
}

.dropdown-title {
    display: flex;
    align-items: center;
}

.dropdown-title * {
    pointer-events: none !important;
}

.loader {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    /* background: #ffffffee; */
    /* border-radius: 1000px; */
    /* border: 1px solid transparent; */

    background: #ffffff88;
    z-index: 10;
    padding: 4px;

    align-items: center;
    justify-content: center;
    stroke: #007ac2;
    stroke-width: 2;
    fill: none;
    opacity: 1;
    transform: scale(1, 1);
    animation: 6s linear 0s infinite alternate-reverse none running loader-color-shift;
}

.loader__svgs {
    /* width: 100%; */
    height: 100%;
    max-height: 100px;
    /* position: relative; */
    overflow: visible;
    opacity: 1;
    transform: scale(1, 1);
}

.loader__svg {
    width: auto;
    height: 100%;
    position: absolute;
    transform: scale(1, 1) translateX(-50%);
    top: 0px;
    left: 0px;
    overflow: visible;
    transform-origin: center center;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: loader-clockwise;
}

@keyframes loader-offset-1 {
    0% {
        stroke-dasharray: 28.0499%, 252.449%;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 140.25%, 140.25%;
        stroke-dashoffset: -84.1497%;
    }

    100% {
        stroke-dasharray: 28.0499%, 252.449%;
        stroke-dashoffset: -280.499%;
    }
}

.loader__svg--1 {
    stroke-dasharray: 28.0499%, 140.25%;
    animation-duration: 0.72s;
    animation-name: loader-offset-1;
}

@keyframes loader-offset-2 {
    0% {
        stroke-dasharray: 56.0998%, 224.399%;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 140.25%, 140.25%;
        stroke-dashoffset: -98.1747%;
    }

    100% {
        stroke-dasharray: 56.0998%, 224.399%;
        stroke-dashoffset: -280.499%;
    }
}

.loader__svg--2 {
    stroke-dasharray: 56.0998%, 140.25%;
    animation-duration: 0.96s;
    animation-name: loader-offset-2;
}

@keyframes loader-offset-3 {
    0% {
        stroke-dasharray: 14.025%, 266.474%;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 140.25%, 140.25%;
        stroke-dashoffset: -77.1373%;
    }

    100% {
        stroke-dasharray: 14.025%, 266.474%;
        stroke-dashoffset: -280.499%;
    }
}

.loader__svg--3 {
    stroke-dasharray: 14.025%, 140.25%;
    animation-duration: 1.16s;
    animation-name: loader-offset-3;
}